@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;700&family=Noto+Serif:wght@400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply bg-tea-white text-tea-black;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: 'Noto Serif', serif;
    }
}

@layer components {
    .btn {
        @apply bg-tea-red text-tea-white py-3 px-8 rounded-none hover:bg-tea-brown transition duration-300 transform hover:-translate-y-1 hover:shadow-lg;
    }

    .section-title {
        @apply text-4xl font-bold mb-12 text-left font-serif text-tea-green;
    }

    .card {
        @apply bg-tea-white p-8 border-l-8 border-tea-brown shadow-xl transition duration-300 hover:shadow-2xl;
    }

    .font-bold {
        font-weight: 500 !important;
    }
}